/* You can add global styles to this file, and also import other style files */

@import "bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
body {
    background-color: #f8f9fe;
    font-family: "Noto Sans JP", sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
}

.error {
    color: red;
    font-size: 11px;
    font-weight: 300;
}

*:focus {
    outline: none !important;
}

.font-9 {
    font-size: 9px;
}

.font-10 {
    font-size: 10px;
}

.font-11 {
    font-size: 11px;
}

.font-12,
pagination .page-item.active .page-link,
pagination .page-link {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.font-30 {
    font-size: 30px;
}

.font-32 {
    font-size: 32px;
}

.font-36 {
    font-size: 36px;
}

.font-38 {
    font-size: 38px;
}

.font-42 {
    font-size: 42px;
}

.thin {
    font-weight: 100;
}

.extraLight {
    font-weight: 200;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.semiBold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.extraBold {
    font-weight: 800;
}

.blackFontWeight {
    font-weight: 900;
}

.tableBorderTop {
    border-top: 1px solid #cccccc;
}

.tableBorderRight {
    border-right: 1px solid #cccccc;
}

.tableBorderBottom {
    border-bottom: 1px solid #cccccc;
}

.tableBorderLeft {
    border-left: 1px solid #cccccc;
}

.orangeBG {
    background: #f4a207;
}

.redBG {
    background-color: #e3212e;
}

.lightOrangeBG {
    background: #f69163;
}

.greenBG {
    background: #85c241;
}

.lightGreyBG {
    background: #f8f8f8;
}

.greyFont {
    color: #838383;
}

.orangeFont {
    color: #f4a207;
}

.redFont {
    color: #f10;
}

.tableShadow {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 7px;
}

.filter {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #e4e4e4;
    height: 40px;
    font-size: 12px;
    padding: 3px 10px;
    margin: 4px 0;
}

.maxWidth-65 {
    max-width: 65px;
}

.maxWidth-70 {
    max-width: 70px;
}

.maxWidth-100 {
    max-width: 100px;
}

.maxWidth-150 {
    max-width: 150px;
}

.maxWidth-240 {
    max-width: 240px;
}

.paddingTop-10 {
    padding-top: 10px;
}

.height-33 {
    height: 33px;
}

.height-36 {
    height: 36px;
}

.editIcon {
    width: 20px;
    height: 20px;
}

.viewIcon {
    width: 40px;
}

.productListImg {
    max-width: 20px;
    max-height: 20px;
}

.productEditInput,
.productEditTextarea {
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    max-width: 240px;
}

.productEditInput {
    height: 30px;
    resize: none;
}

.productEditTextarea {
    resize: none;
    height: 75px;
}

.imageUpload input {
    display: none;
}

.cancelBtn {
    border: 2px solid #464646;
}

.pointer {
    cursor: pointer;
}

.border-radius-3 {
    border-radius: 3px;
}

pagination .page-item.active .page-link {
    background-color: #f4a207;
    border-color: #f4a207;
}

pagination ul {
    display: inline-flex !important;
    margin: 15px auto;
}

.productsInList {
    width: 160px;
    border: 1px solid #d9d9d9;
    float: left;
    margin: 12px;
    min-height: 225px;
}

.productsInList .proImg {
    width: 158px;
    height: 158px;
}

.activeStatus,
.deactiveStatus {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 20px;
    margin-right: 3px;
    box-shadow: 0px 0px 3px #2f2f2f;
    border: 2px solid #ffffff;
}

.activeColor {
    background: #12b612;
}

.inactiveColor {
    background: red;
}

.proEditIcon {
    top: 0;
    height: 25px;
    width: 25px;
    left: 0;
    background: #fff;
}

.proActiveIcon {
    right: 10px;
    top: 10px;
}

.activateDeactivateIcon {
    width: 15px;
    height: 15px;
}

.listIcon {
    width: 20px;
}

.orangeBorder {
    border: 2px solid #f4a207;
}

.maxWidth-240 {
    max-width: 240px;
}

.deleteIcon {
    top: 8px;
    right: 0;
    cursor: pointer;
}

.tableDiv {
    background-color: #fff;
    border-bottom: 1px solid #e7e7e7;
}

.minQtySelect {
    width: 60px;
    right: 0;
    border-left: 1px solid #bbb;
    border-right: none;
    border-bottom: none;
    border-top: none;
}

.loginError {
    max-width: 300px;
    text-align: left;
    margin: 0 auto;
}

.tableHead {
    background: #f6f9fc;
    border-bottom: 1px solid #e7e7e7;
}

.dropdown-menu {
    margin: 5px;
    padding: 10px 0 !important;
    background-color: #fff !important;
    border: 1px solid #ebeef5 !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #000 !important;
    background-color: #f8f9fe !important;
}

.popover,
.vendorProductEdit .card {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.popover-header {
    background-color: #f8f9fe !important;
    border-bottom: 1px solid #e4e4e4 !important;
    font-size: 14px !important;
}

.popover-body {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.tableDiv:hover {
    background-color: #f6f9fc;
}

.manageVendorModal .modal-body {
    padding: 1rem 0;
    width: 760px;
}

.forgotPasswordModal .modal-content,
.reasonPopup .modal-content {
    background: #f4a207;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.manageVendorModal .modal-footer {
    border-top: 1px solid #fff !important;
}

.popupBtn {
    height: 28px;
    width: 90px;
}

.productList .nav-pills .nav-link.active,
.nav-pills .show .nav-link {
    color: #fff;
    background-color: #f4a207;
}

.productList .nav-pills .nav-link {
    border-radius: 0;
}

.productList .nav-link {
    padding: 8px 12px;
    color: #000;
    font-weight: 300;
    border: 1px solid #f4a207;
    font-size: 14px;
    width: 200px;
    margin: 0 15px 0 0;
    text-align: center;
}

.btnShadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.vendorProductEdit .card-header {
    padding: 0;
    background-color: #fff;
}

.vendorProductEdit .card-body {
    padding: 0 15px;
}

.customToast {
    color: #000;
    font-size: 14px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

.customToast.toast-success,
.customToast.toast-error {
    background-color: #eee;
    border-color: #cfcfcf;
}

.customToast.toast-success {
    background-image: url(assets/images/success.png) !important;
}

.customToast.toast-error {
    background-image: url(assets/images/error.png) !important;
}

.customToast .toast-title {
    font-weight: 400 !important;
}

.statusBadge {
    border-radius: 3px;
    padding: 1px 4px;
}

.bulkEditSelect {
    width: 45px;
    right: 0;
    border-left: 1px solid #bbb;
    border-right: none;
    border-bottom: none;
    border-top: none;
    height: 24px;
}

.newStatus {
    background: #3300ff;
}

.approvedStatus {
    background: #2ebb00;
}

.deactivatedStatus {
    background: #ff4646;
}

.inactiveStatus {
    background: #606060;
}

.light {
    font-weight: 300;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

.height-53 {
    height: 53px;
}

.maxWidth30 {
    max-width: 30px;
}

.minHeight35 {
    min-height: 35px;
}


/***************************************************************/

//For file input style
.fileInput {
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    background: #f9f9f9;
    border: 0.5px dashed #b7b7b7;
    line-height: 17px;
}

.fileInput [type="file"] {
    display: block;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fileInput [type="file"] {
    cursor: pointer;
}

#custom_calendar {
    .fc-toolbar-title {
        font-size: 16px;
    }
}

.fc .fc-bg-event .fc-event-title {
    text-align: center!important;
    padding-top: 1.5rem !important;
}

.max-width105 {
    max-width: 105px!important;
}

.greyBg {
    background-color: gray;
}

.popover{
    position: absolute;
    .popover-arrow::before{
        border-width: 0 !important;
    }
}

/***************************************************************/